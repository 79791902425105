import * as React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
        <Link to="/">Home</Link>.
    </main>
  )
}

export default NotFoundPage
